import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/sistema/permisos`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  agregar(permiso) {
    return apiClient.post('', permiso)
  },

  agregarMultiples(idRol, idsFunciones) {
    return apiClient.post('', { id_rol: idRol, ids_funciones: idsFunciones })
  },

  quitar(idRol, idFuncion) {
    return apiClient.delete('', {
      params: { id_rol: idRol, id_funcion: idFuncion }
    })
  },

  quitarMultiples(idRol, idsFunciones) {
    return apiClient.delete('', {
      params: { id_rol: idRol, ids_funciones: idsFunciones }
    })
  },

  permisosJSON(params) {
    return apiClient.get(`${API}/sistema/permisos.json`, { params: params })
  }
}
